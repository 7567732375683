import Web3 from "web3"
import { BlockchainsConfig } from "./config/BlockchainsConfig";
import { createContext, useContext } from "react";

export type TBaseBlockchain = {
    name: string;
    chainId: number;
    rpc: string;
    explorerBaseUrl: string;
}

export type TBlockchain = {
    name: string;
    chainId: number;
    web3: Web3;
    explorer: Explorer
}

class Explorer {
    constructor(private readonly baseUrl: string) {}
    tx(txHash: string) {
        return this.baseUrl.replace('%s', `tx/${txHash}`)
    }
    addr(address: string) {
        return this.baseUrl.replace('%s', `address/${address}`)
    }
    link() {
        return this.baseUrl.replace('%s', '')
    }
}

export function getBlockchains(): TBlockchain[] {
    return BlockchainsConfig.map(blockchain => ({
        ...blockchain,
        explorer: new Explorer(blockchain.explorerBaseUrl),
        web3: new Web3(blockchain.rpc)
    }))
}
