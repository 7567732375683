import {
  Link,
  useLocation,
} from "react-router-dom";

export  function Navigation() {
  const location = useLocation();
  const routes = [
    { path: '/', name: 'Summary'},
    { path: '/check-routers', name: 'Check routers'},
  ]

  return (
    <ul className="nav nav-tabs card-header-tabs">
      {routes.map((route, key) =>
          <li className="nav-item" key={key}>
            <Link to={route.path} className={"nav-link" + (location.pathname === route.path ? ' active' : '')}>{route.name}</Link>
          </li>
      )}
    </ul>
  )
}