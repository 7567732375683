import { Address } from "../Design/Address";
import { SupportedRouter } from "./SupportedRouterFlag";
import { getBaseContractSet } from "../../config/contracts/contractSet";
import { ContractName } from "../../config/contracts/types";
import { useGetBlockchains } from "../../contexts/GetBlockchains";

export function CheckRoutersTable(props: { addresses: string[] }) {
    const blockchains = useGetBlockchains();

    return (
      <table className='table table-bordered table-hover border-primary table-responsive-sm contracts-table'>
          <thead>
              <tr>
                  <th>Network</th>
                  { props.addresses.map(addr => <th><Address>{addr}</Address></th>) }
              </tr>
          </thead>
          <tbody>
              {blockchains.map(blockchain =>
                  <tr>
                      <td>{blockchain.name}</td>
                      { props.addresses.map(address =>
                            <SupportedRouter address={address} contract={getBaseContractSet(blockchain).get(ContractName.DeBridgeRouter)!}/>
                      ) }
                  </tr>
              )}
          </tbody>
      </table>
    )
}