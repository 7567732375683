import { TBaseBlockchain } from "../Blockchains";

export const BlockchainsConfig: TBaseBlockchain[] = [
    {
        name: 'Ethereum',
        chainId: 1,
        rpc: 'https://silent-lingering-silence.quiknode.pro/070e558d9af4d777c940ce38c408742006c8d403/',
        explorerBaseUrl: 'https://etherscan.io/%s'
    },
    {
        name: 'BNB',
        chainId: 56,
        rpc: 'https://bsc-dataseed.binance.org',
        explorerBaseUrl: 'https://bscscan.com/%s'
    },
    {
        name: 'Polygon',
        chainId: 137,
        rpc: 'https://ultra-wispy-wave.matic.quiknode.pro/17084ed5fb8536f171035b36d26638181651b8d1/',
        explorerBaseUrl: 'https://polygonscan.com/%s'
    },
    {
        name: 'Arbitrum',
        chainId: 42161,
        rpc: 'https://arbitrum-mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
        explorerBaseUrl: 'https://arbiscan.io/%s'
    },
    {
        name: 'Avalanche',
        chainId: 43114,
        rpc: 'https://api.avax.network/ext/bc/C/rpc',
        explorerBaseUrl: 'https://snowscan.xyz/%s'
    },
    {
        name: 'Linea',
        chainId: 59144,
        rpc: 'https://rpc.linea.build/',
        explorerBaseUrl: 'https://lineascan.build/%s'
    },
    {
        name: 'Base',
        chainId: 8453,
        rpc: 'https://mainnet.base.org',
        explorerBaseUrl: 'https://basescan.org/%s'
    },
    {
        name: 'Optimism',
        chainId: 10,
        rpc: 'https://little-capable-moon.optimism.quiknode.pro/f44d9e8941ea30c9e0dca9b0a958424694468e4d/',
        explorerBaseUrl: 'https://optimistic.etherscan.io/%s'
    },
    {
        name: 'Fantom',
        chainId: 250,
        rpc: 'https://rpc.ftm.tools',
        explorerBaseUrl: 'https://ftmscan.com/%s'
    },
    {
        name: 'Neon',
        chainId: 245022934,
        rpc: 'https://neon-mainnet.everstake.one',
        explorerBaseUrl: 'https://neonscan.org/%s'
    },
    {
        name: 'Gnosis',
        chainId: 100,
        rpc: 'https://rpc.eu-central-2.gateway.fm/v4/gnosis/non-archival/mainnet',
        explorerBaseUrl: 'https://gnosisscan.io/%s'
    },
    {
        name: 'LL',
        chainId: 1890,
        rpc: 'https://replicator.phoenix.lightlink.io/rpc/v1',
        explorerBaseUrl: 'https://phoenix.lightlink.io%s'
    },
    {
        name: 'Metis',
        chainId: 1890,
        rpc: 'https://metis-mainnet.public.blastapi.io',
        explorerBaseUrl: 'https://explorerBaseUrl.metis.io/%s'
    },
    {
        name: 'Bitrock',
        chainId: 7171,
        rpc: 'https://connect.bit-rock.io',
        explorerBaseUrl: 'https://explorerBaseUrl.bit-rock.io/%s'
    },
];