import Web3 from "web3";
import { getAddressAt } from "./Contract";

const IMPL_SLOT = '0x360894a13ba1a3210667c828492db98dca3e2076cc3735a920a3ca505d382bbc'

export type TTransparentUpgradableProxyMetadata = {
    address: string;
    implementation: string;
    implementationBytecode: string;
}

export async function getTransparentUpgradableProxyMetadata(web3: Web3, contractAddress: string): Promise<TTransparentUpgradableProxyMetadata> {
    const implementationAddress = await getAddressAt(web3, contractAddress, IMPL_SLOT);
    const implementationBytecode = await web3.eth.getCode(implementationAddress);

    return {
        address: contractAddress,
        implementation: implementationAddress,
        implementationBytecode,
    }
}