import Web3, { Contract, FMT_BYTES } from "web3";
import { getAddressAt } from "./Contract";
import { ProxyAdminAbi } from "./abis/ProxyAdmin.abi";
import { GnosisSafeAbi } from "./abis/GnosisSafe.abi";

export type TProxyAdminMetadata = {
    address: string;
    owner: string;
    safeParams?: {
        ownerIsContract: boolean;
        ownersCount: number
        threshold: number
    }
}

const ADM_SLOT = '0xb53127684a568b3173ae13b9f8a6016e243e63b6e8ee1178d6a717850b5d6103';

async function getSafeParams(safe: Contract<typeof GnosisSafeAbi>): Promise<TProxyAdminMetadata['safeParams']> {
    // const safe = new web3.eth.Contract(GnosisSafeAbi, ownerAddress);
    return {
        ownerIsContract: true,
        ownersCount: (await safe.methods.getOwners().call()).length,
        threshold: await safe.methods.getThreshold().call()
    }
}

export async function getProxyAdminMetadata(web3: Web3, contractAddress: string): Promise<TProxyAdminMetadata> {
    const proxyAdminAddress = await getAddressAt(web3, contractAddress, ADM_SLOT);

    const proxyAdmin = new web3.eth.Contract(ProxyAdminAbi, proxyAdminAddress);
    const ownerAddress = await proxyAdmin.methods.owner().call();
    const codeAtOwnerAddress = await web3.eth.getCode(ownerAddress);
    const ownerIsContract = codeAtOwnerAddress !== '0x';

    return {
        address: proxyAdminAddress,
        owner: ownerAddress,
        safeParams: ownerIsContract ? await getSafeParams(new web3.eth.Contract(GnosisSafeAbi, ownerAddress)) : undefined
    }
}