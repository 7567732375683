import './Address.css'

export function Address(props: { children: string }) {
    const addr = props.children;
    if (addr.length != 42) return <>addr</>;

    const m = addr.match(/^0x(.{8})(.+)(.{4})$/i);
    if (!m) return <>addr</>;

    const [, head, mid, heel] = m;
    return <div style={{position:'relative'}}>
        <span className='for-search p-0'>{addr}</span>
        0x{head}<span className="hidden-middle">{mid}</span>{heel}
    </div>
}