import { useState, useEffect } from "react";
import Web3 from "web3";
import { TBlockchain } from "../../Blockchains";
import { Contract } from "../../config/contracts/contractSet";
import { ContractName } from "../../config/contracts/types";


export function SupportedRouter({ address, contract }: { address: string, contract: Contract<ContractName.DeBridgeRouter> }) {
    const [routerEnabled, setRouterEnabled] = useState<boolean | "error">();

    if (undefined === routerEnabled) {
        contract.contract.methods.supportedRouters(address).call()
            .then(async (isEnabled: any) => {
                setRouterEnabled(isEnabled)
            })
            .catch(e => {
                console.error(e)
                setRouterEnabled("error")
            })
        return <td>👀</td>;
    }

    if ("error" === routerEnabled) return <td>😨</td>

    return (<td>
        <div>{routerEnabled ? '✅' : '⛔️'}</div>
    </td>)
}