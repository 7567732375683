import { useContext } from "react"
import { HighlightedStringContext } from "../../contexts/GetHighlightedString"

export function HightlistedString({ s }: ({ s: string })) {
    const {highlightedString, setHighlightedString} = useContext(HighlightedStringContext)
        return <span
            onMouseOver={() => setHighlightedString(s)}
            onMouseOut={() => setHighlightedString(undefined)}
         style={
            {
                backgroundColor: String(s) == highlightedString ? 'red' : 'inherit'
            }
        }>{s}</span>

}