import { Navigation } from "./Navigation";

export function CommonLayout({children}: ({ children: JSX.Element | JSX.Element[] | string })) {
    return (
      <div className="card">
        <div className="card-header">
          <Navigation/>
        </div>
        <div className="card-body">
          <div className='container-lg'>
            {children}
          </div>
        </div>
      </div>
    )
  }