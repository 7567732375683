import React, { useEffect, useState } from 'react';
import './Table.css';
import { TBlockchain } from '../../Blockchains';
import { ContractVersion } from './ContractVersion';
import { ContractName, contractNames } from '../../config/contracts/types';
import { getContracts, TContractSet } from '../../config/contracts/contractSet';

export function BlockchainRow({ blockchain }: { blockchain: TBlockchain }) {
    const [contractSet, setContractSet] = useState<TContractSet | undefined>()

    useEffect(() => {
        if (!contractSet)
            getContracts(blockchain)
                .then(v => setContractSet(v))
    }, [contractNames])

    return (<tr>
        <td><a href={blockchain.explorer.link()}>{blockchain.name}</a> <div><small>{blockchain.chainId}</small></div></td>
        {contractNames.map((contractName, key) => {
            const contract = contractSet?.get(ContractName[contractName])
            if (!contractSet) {
                return <td>👀</td>
            }
            if (!contract) {
                return <td key={key}>N/A</td>
            }
            else {
                return <ContractVersion key={key} blockchain={blockchain} contract={contract} />
            }
        }
        )}
    </tr>)
}